import { w3cwebsocket } from "websocket";
import { uniqueId } from "lodash";

const randomNumber = Math.floor(Math.random() * (999 - 100 + 1) + 100);

const client = new w3cwebsocket(process.env.REACT_APP_WEBSOCKET_SERVER_ADDRESS);
//Display message on opening Websocket client
client.onopen = () => {
    console.log("Client opened")
}
//Display message on closing Websocket client
client.onclose = () => {
    console.log("Client closed")
}

function GenerateID() {
    const newNumber = randomNumber + Number(uniqueId());
    return newNumber;
}
//Connect and authenticate to Websocket server
export function ConnectToServer(callback) {
    //Display message on opening websocket client
    client.onmessage = (message) => {
        //Parse received message
        const response = JSON.parse(message.data);
        //Check for authentication request from Websocket server and sends access token
        response.type === "auth_required" && SendMessage({ "type": "auth", "access_token": process.env.REACT_APP_WEBSOCKET_ACCESS_TOKEN })
        if (response.type === "auth_ok") {
            callback("connected");
            GetStates();
            SendMessage({ "id": 10, "type": "subscribe_events", "event_type": "state_changed" })
        }
    }

}
export function ListenForMessages(receivedMessage) {
        client.onmessage = (message) => {
            //Parse received message
            const response = JSON.parse(message.data);
            //Check for authentication request from Websocket server and sends access token
            receivedMessage(response)
        }
}
//Send message to Websocket server to get all current States
export const GetStates = () => {
    const object = { "id": 1, "type": "get_states" }
    SendMessage(object)
}

//Toggle Light
export function SetLightToggle(light, state) {
    let object = {
        "id": GenerateID(),
        "type": "call_service",
        "domain": "light",
        "service": "turn_" + state,
        "service_data": {
            "entity_id": light
        },
    }
    SendMessage(object);
}

//Set Light Color
export function SetLightColor(light, type, color) {
    let object = {
        "id": GenerateID(),
        "type": "call_service",
        "domain": "light",
        "service": "turn_on",
        "service_data": {}
    }
    if (type === "color") {
        object.service_data = {
            "entity_id": light,
            "hs_color": color
        }
    }
    SendMessage(object);
}
//Send message to Websocket server
export function SendMessage(message) {
    client.send(JSON.stringify(message));
}