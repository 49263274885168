import React, { Component } from 'react'
import { ConnectToServer, SetLightToggle, SetLightColor, ListenForMessages } from '../common/WebSocket';

export default class Light extends Component {
    constructor(props) {
        super(props);
        this.state = {
            light: "light.night_light",
            lightState: "",
            attributes: {
                "color_mode": "",
                "brightness": "",
                "hs_color": [],
                "rgb_color": [0, 0, 0],
            }
        }
        this.InitLight = this.InitLight.bind(this);
        this.UpdateLight = this.UpdateLight.bind(this);
        this.UpdateLight = this.UpdateLight.bind(this);
        this.HandleToggle = this.HandleToggle.bind(this);
    }

    //Initializes Selected Light
    InitLight(light, states) {
        const lightState = states.filter((state) => { return state.entity_id === light })
        //Push Light values from Websocket server to states
        this.setState({
            attributes: {
                "color_mode": lightState[0].attributes.color_mode,
                "brightness": lightState[0].attributes.brightness,
                "hs_color": lightState[0].attributes.hs_color,
                "rgb_color": lightState[0].attributes.rgb_color,
            },
            lightState: lightState[0].state,
        }, () => { console.log(this.state) })
    };
    //Updates Selected Light
    UpdateLight(light, state) {

        //Checks for correct light
        if (state.entity_id === light) {
            //Push Light values from Websocket server to states
            this.setState({
                attributes: {
                    "color_mode": state.new_state.attributes.color_mode,
                    "brightness": state.new_state.attributes.brightness,
                    "hs_color": state.new_state.attributes.hs_color,
                    "rgb_color": state.new_state.attributes.rgb_color,
                },
                lightState: state.new_state.state,
            })
        };
    }
    HandleToggle(event) {
        //Calls websocket to update Light Toggle State
        SetLightToggle(this.state.light, event.target.checked ? "on" : "off");
    }
    componentDidMount() {
        console.log(process.env.REACT_APP_WEBSOCKET_SERVER_ADDRESS);
        //Connection with Home Assistant Websocket Server
        ConnectToServer(() => {
            //Starts listening for message from Websocker Server
            ListenForMessages((message) => {
                //Gets initial states from Websocket Server
                message.id === 1 && this.InitLight(this.state.light, message.result);
                //Checks for update state and filters if it's current light
                (message.type === "event" && message.event.event_type === "state_changed") && this.UpdateLight(this.state.light, message.event.data);
            })
        })
    }
    render() {
        const lightToggle = this.state.lightState === "on" ? true : false;
        return (
            <div style={{ height: window.innerHeight }} className={`mx-auto w-fit flex flex-col justify-center items-center`}>
                <div className='test'></div>
                <div className='current-color w-8 h-8 rounded-full transition-all duration-300 ease-in' style={{ 'backgroundColor': this.state.lightState === "off" ? "rgb(0,0,0)" : `rgb(${this.state.attributes.rgb_color[0]},${this.state.attributes.rgb_color[1]},${this.state.attributes.rgb_color[2]})` }}></div>
                <div className='toggle-light flex flex-col items-center'>
                    {lightToggle ? <img className='w-48 h-48 object-contain' src="/img/on.png" alt="on" /> : <img className='w-48 h-48 object-contain' src="/img/off.png" alt="off" />}
                    <input type="checkbox" className="toggle toggle-lg" checked={lightToggle} onChange={this.HandleToggle} />
                </div>
                <div className='buttons'>
                    <div className='row flex justify-center justify-evenly py-1'>
                        <button className="btn btn-circle bg-blue-500 mx-1" onClick={() => SetLightColor(this.state.light, "color", [20, 100])}>😭</button>
                        <button className="btn btn-circle bg-pink-500 mx-1" onClick={() => SetLightColor(this.state.light, "color", [50, 100])}>🥰</button>
                        <button className="btn btn-circle bg-red-700 mx-1" onClick={() => SetLightColor(this.state.light, "color", [70, 100])}>😒</button>

                    </div>
                    <div className='row flex justify-center justify-evenly py-1'>
                        <button className="btn btn-circle bg-purple-500 mx-1" onClick={() => { }}>🥵</button>
                        <button className="btn btn-circle bg-yellow-500 mx-1" onClick={() => { }}>🐶</button>
                        <button className="btn btn-circle bg-green-500 mx-1" onClick={() => { }}>🥺</button>
                    </div>
                </div>
            </div >
        )
    }
}
