import LightControl from './components/pages/LightControl';
function App() {
  return (
    <div className="App">
      <LightControl />
    </div>
  );
}

export default App;
